<template>
	<v-sheet flat>
		<mw-edit
			:id="id"
			storeModule="profileFields"
			:model="model"
			@saved="$emit('saved')"
			title-key="name"
			path="profile-fields"
		>
		</mw-edit>
		<secondary-navigation>
			<template v-slot:main>
				<div v-if="id" class="ma-2">
					<nav-item
						:link="`/profile-search/${id}`"
						text="Find Participants"
						icon="mdi-magnify"
					></nav-item>
					<v-divider class="mb-4 mt-3"></v-divider>
				</div>
			</template>
		</secondary-navigation>
	</v-sheet>
</template>

<style lang="less"></style>

<script type="text/javascript">
import ProfileField from "@/models/ProfileField";
import MwEdit from "@c/ui/MwEdit";
import SecondaryNavigation from "@c/navigation/Secondary";
import NavItem from "@/components/navigation/Item";

export default {
	name: "EditProfileField",
	props: {
		id: { type: String }, // @doc the id string of the object - will add a new document if not passed
	},
	computed: {
		model() {
			return ProfileField;
		},
	},
	components: {
		MwEdit,
		SecondaryNavigation,
		NavItem,
    },
};
//
</script>
"
